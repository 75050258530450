
























import { defineComponent } from '@vue/composition-api';
import { NotificationAdministratorIcon } from '../icons';

export default defineComponent({
    name: 'AdministratorDigest',
    components: { NotificationAdministratorIcon },
    props: {
        notification: {
            type: Array,
            required: true,
        },
    },
    setup() {
        const getTitle = (event_type: string) => {
            let title = '';
            switch (event_type) {
                case 'admin.user.deleted':
                    title = 'Users Deleted';
                    break;
                case 'admin.organisation.deleted':
                    title = 'Organisation Deleted';
                    break;

                default:
                    break;
            }
            return title;
        };
        const getMessage = (event: any) => {
            let msg = '';
            switch (event.event_type) {
                case 'admin.user.deleted':
                    msg = `User ${event.referenceTitle} has been deleted along with the corresponding data`;
                    break;
                case 'admin.organisation.deleted':
                    msg = `Organisation with id #${event.reference_id} has been deleted along with the corresponding data`;
                    break;

                default:
                    break;
            }
            return msg;
        };

        return {
            getTitle,
            getMessage,
        };
    },
});
