










































































import { Card, ConfirmModal, Pagination, SvgImage } from '@/app/components';
import store from '@/app/store';
import { NotificationOverview } from '@/modules/notification/components';
import { useNotifications } from '@/modules/notification/composable/notifications';
import { useAxios } from '@/app/composable';
import { RefreshIcon } from '@vue-hero-icons/outline';
import { computed, defineComponent, onBeforeUnmount, ref, watch } from '@vue/composition-api';
import { NotificationAPI } from '../api';
import { RefreshPageKeys } from '../constants';

export default defineComponent({
    name: 'Notifications',
    metaInfo: {
        title: 'Notifications',
    },
    components: { Card, ConfirmModal, NotificationOverview, SvgImage, Pagination, RefreshIcon },
    setup(props, { root }) {
        const { exec, error } = useAxios(true);

        const currentPage = ref<number>(1);
        const pageSize = 10;
        const notificationsElement = ref<HTMLElement>();
        const { markAllAsSeen, showMarkAllAsSeenModal } = useNotifications(root);
        const visibleNotifications = ref<any>([]);
        const toatlNotifications = ref<number>(1);
        const asSeenCount = ref<number>(0);

        const notifications = computed(() => {
            return store.state.notificationEngine.notifications;
        });

        const refresh = computed(() => {
            return store.state.notificationEngine.refresh[RefreshPageKeys.Notifications];
        });

        const fetchNotifications = async () => {
            await exec(NotificationAPI.fetch(currentPage.value, pageSize))
                .then((res) => {
                    visibleNotifications.value = res?.data.notifications;
                    toatlNotifications.value = res?.data.count;
                    asSeenCount.value = res?.data.countUnseen;
                })
                .catch(() => {
                    (root as any).$toastr.e('Getting Notification failed', 'Error');
                });
            store.dispatch.notificationEngine.disableRefreshAction(RefreshPageKeys.Notifications);
        };

        const destroy = async (id: number) => {
            await exec(NotificationAPI.delete(id))
                .then(() => {
                    store.commit.notificationEngine.DELETE_NOTIFICATION(id);
                    (root as any).$toastr.removeByType('success');
                    (root as any).$toastr.s('Notification deleted successfully', 'Success');

                    fetchNotifications();
                })
                .catch(() => {
                    const errorMessage = error.value.response.data.message;
                    (root as any).$toastr.e(`Notification could not be deleted: ${errorMessage}`, 'Error');
                });
        };

        const enableMarkAllAsSeen = computed(() => {
            return asSeenCount.value !== 0;
        });

        const markAsSeen = async (id: number) => {
            await store.dispatch.notificationEngine.changeSeenAt(id);
            fetchNotifications();
        };

        const scrollToTop = () => {
            if (notificationsElement.value) {
                notificationsElement.value.scrollIntoView({ behavior: 'smooth' });
            }
        };

        watch(
            () => currentPage.value,
            async () => {
                fetchNotifications();
            },
        );

        onBeforeUnmount(() => {
            if (refresh.value) store.dispatch.notificationEngine.disableRefreshAction(RefreshPageKeys.Notifications);
        });

        fetchNotifications();

        return {
            destroy,
            notifications,
            pageSize,
            enableMarkAllAsSeen,
            markAsSeen,
            markAllAsSeen,
            showMarkAllAsSeenModal,
            visibleNotifications,
            refresh,
            toatlNotifications,
            currentPage,
            scrollToTop,
            notificationsElement,
            fetchNotifications,
        };
    },
});
