



























import { defineComponent, ref } from '@vue/composition-api';
import { NotificationSuggestionIcon } from '../icons';

export default defineComponent({
    name: 'ModelSuggestionDigest',
    components: { NotificationSuggestionIcon },
    props: {
        notification: {
            type: Object,
            required: true,
        },
    },
    setup() {
        const isHovered = ref(false);

        const setEventTitles = (events: [any]) => {
            return events
                .map(function (item) {
                    return item['payload']['suggestionName'];
                })
                .join(', ');
        };

        const setEventMessage = (eventType: string) => {
            switch (eventType) {
                case 'concept_created':
                    return 'Concepts that were <b>suggested</b>: ';
                case 'concept_approved':
                    return 'Concepts that you have proposed and were <b>included</b> in the data model: ';
                case 'concept_rejected':
                    return 'Concepts that you have proposed and were <b>rejected</b>: ';
                default:
                    return null;
            }
        };

        return {
            isHovered,
            setEventTitles,
            setEventMessage,
        };
    },
});
