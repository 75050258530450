







































import { computed, defineComponent, ref } from '@vue/composition-api';
import { NotificationWorkflowIcon } from '../icons';

export default defineComponent({
    name: 'WorkflowDigest',
    components: { NotificationWorkflowIcon },
    props: {
        notification: {
            type: Object,
            required: true,
        },
        referenceId: {
            type: String,
            required: true,
        },
    },
    setup(props) {
        const isHovered = ref(false);

        const getPaylod = computed(() => {
            const payload = {
                name: 'history',
                params: {
                    id: props.notification.payload.executionId,
                    workflowId: props.referenceId,
                    status: props.notification.eventType,
                    type: props.notification.payload.referenceType,
                },
                query: {
                    executionId: props.notification.payload.executionId || undefined,
                },
            };
            return payload;
        });

        return {
            isHovered,
            getPaylod,
        };
    },
});
